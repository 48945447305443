import backendConfig from './backendConfig';
export type getPatientsType = { search?: string; pageNumber?: string; pageSize?: number };
export type getPatientType = { patientUid: string };
export type resetPasswordType = { uid: string };
export type getPatientAppointmentsType = { uid: string };
export type editPatientType = {
    uid: string;
    type: string;
    firstName: string;
    lastName: string;
    avatar: string;
    phone: string;
    country: string;
    city: string;
    bd: string;
    gender: string;
    fakeId: string;
};
class PatientModel {
    static getPatients() {
        return backendConfig.config.getData<getPatientsType>({
            endPoint: 'admin/patient',
            queryDefault: { search: '', pageNumber: '', pageSize: '' },
        });
    }

    static getPatient() {
        const endPoint = 'admin/patient/:patientUid';

        return backendConfig.config.getData<getPatientType>({ endPoint });
    }

    static resetPassword() {
        const endPoint = 'admin/patient/:uid/resetPassword';

        return backendConfig.config.updateData<resetPasswordType>({ endPoint });
    }

    static getAppointments() {
        const endPoint = 'patient/appointments/:patientUid';

        return backendConfig.config.getData<getPatientType>({ endPoint });
    }

    static cashbox() {
        const endPoint = 'patient/cashbox/:patientUid';

        return backendConfig.config.getData<getPatientType>({ endPoint });
    }

    static editPatient() {
        const endPoint = 'admin/patient/:uid/:type';
        const bodyDefault = {
            firstName: undefined,
            lastName: undefined,
            avatar: undefined,
            phone: undefined,
            country: undefined,
            city: undefined,
            bd: undefined,
            gender: undefined,
            fakeId: undefined,
        };
        return backendConfig.config.updateData<editPatientType>({ endPoint, bodyDefault });
    }
}

export default PatientModel;
