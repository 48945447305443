import { useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { TableComp } from '@curawella/admin-package';
import PatientModel, { getPatientsType } from '../../models/Patients';
import Loading from '../../components/Loading';
import moment from 'moment';
import RenderChart, { fillMissingDates } from '../../components/Chart';

const pageLength = 10;
const headers = [{ key: 'uid' }, { key: 'name' }, { key: 'createdAt', format: (v: string) => moment(v).format('llll') }];

const Patients = (): JSX.Element => {
    const [loading, execute, data] = PatientModel.getPatients();
    const history = useHistory();
    const patients = data?.patients.map((p: any) => ({ ...p, name: `${p.firstName} ${p.lastName}` })) || [];

    /**
     * Get patients
     */
    const getPatients = useCallback(
        (pageNumber = undefined, query = undefined) => {
            const d: getPatientsType = { pageSize: pageLength };

            if (query) {
                d.search = query;
            }
            if (pageNumber) {
                d.pageNumber = pageNumber;
            }

            execute(d);
        },
        [execute],
    );

    // Call getPatientData function to get patient data
    useEffect(() => {
        getPatients();
    }, [getPatients]);

    return (
        <>
            {loading && <Loading />}
            <TableComp
                title={'Patients'}
                headers={headers}
                data={patients}
                pagination={{ pageLength }}
                showDataLength
                dataLength={data?.length}
                rowAction={(patient) => history.push(`/patient/${patient.uid}`)}
                endpoint={getPatients}
            />

            <RenderChart title='Patients' headers={['count']} data={data ? fillMissingDates(data.growth) : []} />
        </>
    );
};

export default Patients;
