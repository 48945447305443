import { useCallback, useEffect, useState } from 'react';
import { DetailsView, formInputTypesEnum, Loading, modalAlert, Patterns } from '@curawella/admin-package';
import { levels } from '../../constants';
import { GetCities } from '../../methods/GetCities';
import { GetCountries } from '../../methods/GetCountries';
import DoctorModel, { getDoctorType } from '../../models/Doctor';
import SpecialtyModel from '../../models/Specialty';
import ImageHelpers from '../../methods/uploadImage';
import RenderChart, { fillMissingDates, fillMissingWeeks, incrementChartData } from '../../components/Chart';
import { refactoringOrders } from '../../components/Orders';

const genderOptions = [
    { value: '', title: 'Select' },
    { value: '1', title: 'Female' },
    { value: '0', title: 'Male' },
];

export type dataType = { doctorUid: string };

type NameType =
    | 'firstName'
    | 'lastName'
    | 'firstNameAr'
    | 'lastNameAr'
    | 'avatar'
    | 'phone'
    | 'country'
    | 'city'
    | 'bd'
    | 'gender'
    | 'about'
    | 'aboutSummary'
    | 'level'
    | 'specialty'
    | 'License'
    | 'NID'
    | 'NIDBack'
    | 'available'
    | 'covid19'
    | 'approved';

const DataDetails = ({ doctorUid }: dataType): JSX.Element => {
    const [cities, setCities] = useState<string[]>();
    const [specialties, setsSpecialties] = useState<{ title: string; value: string }[]>([{ title: '', value: '' }]);

    const [loadingEdit, executeEdit] = DoctorModel.editDoctorDetails();
    const [doctorLoading, executeDoctor, doctorData] = DoctorModel.getDoctor();
    const [allSpecialtiesLoading, executeAllSpecialties] = SpecialtyModel.getAllSpecialties();

    console.log('doctorData', doctorData);
    const {
        firstName,
        lastName,
        firstNameAr,
        lastNameAr,
        avatar,
        country,
        phone,
        email,
        city,
        bd,
        gender,
        about,
        aboutSummary,
        level,
        specialtyNameEn,
        session,
        online,
        message,
        followUpWeeks,
        followup,
        foreignOnline,
        facebookLink,
        playStoreLink,
        websiteLink,
        appStoreLink,
        fakeId,
        licNum,
        License,
        NID,
        nidNum,
        NIDBack,
        available,
        covid19,
        approved,
    } = doctorData?.doctor || {};
    const orders = doctorData?.orders || [];
    const patientList = doctorData?.patientList || [];
    const patientListInc = doctorData?.patientListInc || [];

    /**
     * Get doctor data
     */
    const getDrData = useCallback(() => {
        const data: getDoctorType = { doctorUid };
        executeDoctor(data);
    }, [doctorUid, executeDoctor]);

    // Call getDrData function to get doctor data
    useEffect(() => {
        getDrData();
    }, [getDrData]);

    const onSubmit = async (e: any, data: Record<NameType, string | boolean | any>, key: string) => {
        const body: any = {
            doctorUid,
            type: key,
            firstName: undefined,
            lastName: undefined,
            email: undefined,
            phone: undefined,
            gender: undefined,
            bd: undefined,
            country: undefined,
            city: undefined,
            avatar: undefined,
            firstNameAr: undefined,
            lastNameAr: undefined,
            specialty: undefined,
            level: undefined,
            session: undefined,
            message: undefined,
            approved: undefined,
            available: undefined,

            about: undefined,
            foreignOnline: undefined,
            covid19: undefined,
            online: undefined,
            followup: undefined,
            followUpWeeks: undefined,
            licNum: undefined,
            License: undefined,
            nidNum: undefined,
            NID: undefined,

            NIDBack: undefined,
            permissionClinicManagement: undefined,
            permissionMessaging: undefined,
            permissionVideoCall: undefined,
            permissionPersonalApp: undefined,
            permissionBooking: undefined,
            permissionPaymentOnsite: undefined,
            permissionPaymentOnline: undefined,
        };

        for (const k in data) {
            const i = k as NameType;
            // body[i] = data[i];

            const element = data[i];

            if (i === 'License') {
                await ImageHelpers.uploadImg('License', element, doctorUid).then((downloadURL) => {
                    body[i] = downloadURL;
                });
            } else if (i === 'avatar') {
                await ImageHelpers.uploadImg('avatar', element, doctorUid).then((downloadURL) => {
                    body[i] = downloadURL;
                });
            } else if (i === 'NID') {
                await ImageHelpers.uploadImg('NID', element, doctorUid).then((downloadURL) => {
                    body[i] = downloadURL;
                });
            } else if (i === 'NIDBack') {
                await ImageHelpers.uploadImg('NIDBack', element, doctorUid).then((downloadURL) => {
                    body[i] = downloadURL;
                });
            } else {
                body[i] = element;
            }
        }

        executeEdit(body)
            .then(() => getDrData())
            .catch((err: any) => {
                console.log('err', err);
                modalAlert({ type: 'ERROR', description: `failed to edit` });
            });
    };
    ///////////////////////////////// COUNTRY & CITY /////////////////////////////////////////////////
    /**
     * Get selected country cities
     */
    const getAllCities = useCallback((currentCountry: string) => {
        const newCities = Object.keys(GetCities(currentCountry));
        setCities(newCities);
    }, []);

    // Call getAllCities function to get selected country cities & update rendered county and city values
    useEffect(() => {
        if (doctorUid) {
            getAllCities(country);
        }
    }, [country, doctorUid, getAllCities]);

    const onChange = (e: any) => {
        const { value, name } = e.currentTarget;
        if (name === 'country') {
            getAllCities(value);
        }
    };

    // Get all specialties
    useEffect(() => {
        executeAllSpecialties(undefined).then((res) => {
            const specialtiesArr = [];
            if (res) {
                for (let i = 0; i < res.length; ++i) {
                    const specialtyName = res[i].en;
                    const specialty = res[i].id;
                    const specialtiesObj = { title: specialtyName, value: specialty };
                    specialtiesArr.push(specialtiesObj);
                }
            }

            setsSpecialties(specialtiesArr);
        });
    }, [executeAllSpecialties]);

    if (loadingEdit || doctorLoading || allSpecialtiesLoading) return <Loading />;

    return (
        <>
            <DetailsView
                content={{
                    uid: { value: doctorUid },
                    name: {
                        value: `${firstName} ${lastName}`,
                        inputs: [
                            { name: 'firstName', defaultValue: firstName },
                            { name: 'lastName', defaultValue: lastName },
                        ],
                    },
                    nameAr: {
                        title: 'Arabic Name',
                        value: `${firstNameAr} ${lastNameAr}`,

                        inputs: [
                            { name: 'firstNameAr', defaultValue: firstNameAr },
                            { name: 'lastNameAr', defaultValue: lastNameAr },
                        ],
                    },
                    avatar: { type: 'IMAGE', value: avatar },
                    phone: { value: phone, inputs: [{ name: 'phone' }] },
                    email: { value: email, inputs: [{ name: 'email' }] },
                    countryCity: {
                        value: `${country}, ${city || '----'}`,
                        inputs: [
                            { name: 'country', type: formInputTypesEnum.SELECT, options: Object.keys(GetCountries()), defaultValue: country },
                            { name: 'city', type: formInputTypesEnum.SELECT, options: cities, defaultValue: city },
                        ],
                    },
                    bd: { value: bd, inputs: [{ name: 'bd', title: 'BirthDate', type: formInputTypesEnum.DATE }] },
                    gender: {
                        value: gender === '1' ? 'Male' : 'Female',
                        inputs: [{ name: 'gender', type: formInputTypesEnum.SELECT, options: genderOptions, defaultValue: gender }],
                    },
                    about: {
                        value: about,
                        inputs: [{ name: 'about', type: formInputTypesEnum.TEXTAREA, defaultValue: about }],
                    },
                    aboutSummary: {
                        value: aboutSummary,
                        inputs: [{ name: 'aboutSummary', type: formInputTypesEnum.TEXTAREA, defaultValue: aboutSummary }],
                    },
                    level: { value: level, inputs: [{ name: 'level', type: formInputTypesEnum.SELECT, options: Object.keys(levels), defaultValue: level }] },
                    specialty: {
                        value: specialtyNameEn,
                        title: 'Specialty name',
                        inputs: [
                            {
                                name: 'specialty',
                                title: 'Specialty name',
                                type: formInputTypesEnum.SELECT,
                                options: specialties,
                                defaultValue: specialtyNameEn,
                            },
                        ],
                    },
                    session: {
                        value: session,
                        inputs: [{ name: 'session', defaultValue: String(session) }],
                    },
                    online: {
                        value: online,
                        inputs: [{ name: 'online', defaultValue: online }],
                    },
                    message: {
                        value: message,
                        inputs: [{ name: 'message', defaultValue: message }],
                    },
                    followUpWeeks: {
                        value: followUpWeeks,
                        title: 'Follow up weeks',
                        inputs: [{ name: 'followUpWeeks', defaultValue: followUpWeeks }],
                    },
                    followup: {
                        value: followup,
                        inputs: [{ name: 'followup', defaultValue: followup }],
                    },
                    foreignOnline: {
                        value: foreignOnline,
                        inputs: [{ defaultValue: foreignOnline, name: 'foreignOnline' }],
                    },
                    fakeId: { value: fakeId },

                    licNum: {
                        value: licNum,
                        title: 'licNum',
                        inputs: [{ title: 'licNum', name: 'licNum', defaultValue: licNum }],
                    },
                    License: { type: 'IMAGE', value: License },

                    nidNum: {
                        value: nidNum,
                        title: 'NID Number',
                        inputs: [{ title: 'NID', name: nidNum, pattern: Patterns.nid, defaultValue: nidNum }],
                    },
                    NID: { title: 'NID', type: 'IMAGE', value: NID },
                    NIDBack: { title: 'NID Back', type: 'IMAGE', value: NIDBack },
                    available: { type: 'TOGGLE', value: available === 1 },
                    // covid19: { type: 'TOGGLE', value: covid19 === 1 },
                    approved: { type: 'TOGGLE', value: approved === 1 },

                    facebookLink: {
                        value: facebookLink,
                        inputs: [{ name: 'facebookLink', pattern: Patterns.link, defaultValue: facebookLink }],
                    },
                    playStoreLink: {
                        value: playStoreLink,
                        inputs: [{ name: 'playStoreLink', pattern: Patterns.link, defaultValue: playStoreLink }],
                    },
                    websiteLink: {
                        value: websiteLink,
                        inputs: [{ name: 'websiteLink', pattern: Patterns.link, defaultValue: websiteLink }],
                    },
                    appStoreLink: {
                        value: appStoreLink,
                        inputs: [{ name: 'appStoreLink', pattern: Patterns.link, defaultValue: appStoreLink }],
                    },
                }}
                onChange={(e) => onChange(e)}
                onSubmit={onSubmit}
            />
            <>
                {/* Number of orders */}
                <div style={{ display: 'inline-block', width: '50%' }}>
                    <RenderChart title='Orders' headers={['amount', 'online', 'onsite']} data={orders ? fillMissingDates(refactoringOrders(orders)) : []} />
                </div>

                {/* Number of patients in patient list */}
                <div style={{ display: 'inline-block', width: '50%' }}>
                    <RenderChart title='# patients' headers={['count']} data={patientList ? fillMissingDates(patientList) : []} />
                </div>
                <RenderChart title='# patients' headers={['count']} data={patientList ? incrementChartData(fillMissingWeeks(patientListInc)) : []} />
            </>
        </>
    );
};
export default DataDetails;
