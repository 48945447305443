import { useParams } from 'react-router-dom';
import { modalAlert } from '@curawella/admin-package';
import PatientModel, { resetPasswordType } from '../../models/Patients';
import styles from './Patient.module.scss';
import ButtonsList from './ButtonsList';
import Data from './Data';
import DataDetails from './DataDetails';

const Patient = (): JSX.Element => {
    const params: { patientUid: string } = useParams();
    const patientUid: string = params.patientUid;

    const [resetPasswordLoading, executeResetPassword] = PatientModel.resetPassword();

    /**
     * Reset password
     */
    const resetPassword = (): void => {
        const data: resetPasswordType = { uid: patientUid };

        executeResetPassword(data).then((password: string) => {
            modalAlert({
                type: 'SUCCESS',
                description: `password has been reset successfully and your new password is ${password}`,
            });
        });
    };

    return (
        <div className={styles['container']}>
            {/* patient data */}
            <DataDetails patientUid={patientUid} />

            {/* ButtonsList: reset password, appointments, orders */}
            <ButtonsList patientUid={patientUid} resetPassword={() => resetPassword()} />
        </div>
    );
};

export default Patient;
