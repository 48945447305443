import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';

import { TableComp } from '@curawella/admin-package';

import styles from './Patient.module.scss';
import PatientModel, { getPatientType } from '../../models/Patients';

import Loading from '../../components/Loading';

type cashBoxData = {
    amount: string;
    appointmentId: number | null;
    firstName: string | null;
    lastName: string | null;
    orderType: string;
    paymentTime: string;
    status: boolean;
    type: string;
};

const tableData = {
    title: 'CashBox',
    search: { show: true },
    showDataLength: true,
    pagination: {
        show: true,
        pageLength: 50,
        showFirst: false,
        showLast: false,
    },
    headers: [
        { title: 'Payment Time', key: 'paymentTime' },
        { title: 'Type', key: 'type' },
        { title: 'Amount', key: 'amount' },
        { title: 'Dr. Name', key: 'name' },
    ],
};

const CashBox = (): JSX.Element => {
    const params: { patientUid: string } = useParams();
    const patientUid: string = params.patientUid;

    const [loading, execute, cashboxData] = PatientModel.cashbox();

    const [renderData, setRenderData] = useState<any[]>([]);

    /**
     * Get cashbox data
     */
    const getCashBoxData = useCallback(() => {
        const data: getPatientType = { patientUid };

        execute(data);
    }, [patientUid, execute]);

    // Call getCashBoxData function to get cashbox data
    useEffect(() => {
        getCashBoxData();
    }, [getCashBoxData]);

    // Call formatAppointments function to handle cashbox data
    useEffect(() => {
        if (cashboxData) {
            formatData(cashboxData.transactions);
        }
    }, [cashboxData]);

    /**
     * Handel cashbox data for rendering
     */
    const formatData = (data: cashBoxData[]) => {
        const arr = data
            .map((item: cashBoxData) => {
                return {
                    amount: parseInt(item.amount) / 100,
                    name: item.firstName !== null && item.lastName !== null ? `${item.firstName} ${item.lastName}` : '-',
                    paymentTime: moment(item.paymentTime, 'YYYY-MM-DDTHH:mm:ssZ').format('DD-MM-YYYY hh:mm a'),
                    type: item.type,
                };
            })
            .sort((a, b) => {
                const first = moment(b.paymentTime, 'DD-MM-YYYY hh:mm a').unix();
                const second = moment(a.paymentTime, 'DD-MM-YYYY hh:mm a').unix();
                return first - second;
            });

        setRenderData(arr);
    };

    if (loading) {
        return <Loading />;
    }

    return (
        <div className={styles['cashbox-container']}>
            {/* header */}
            {cashboxData && <p className={styles['header']}>Current Cashbox: {cashboxData.balance / 100} EGP</p>}

            {renderData.length > 0 ? (
                <TableComp
                    title={tableData.title}
                    headers={tableData.headers}
                    data={renderData}
                    pagination={tableData.pagination}
                    showDataLength={tableData.showDataLength}
                />
            ) : (
                // no cashbox data
                <p className={styles['no-data']}>There is no services</p>
            )}
        </div>
    );
};

export default CashBox;
